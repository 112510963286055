import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './FooterLink.css'

import arrowIcon from '/images/icons/arrow-right.raw.svg'

export function FooterLinkCookieButton() {
  const { __ } = useTranslate()

  return (
    <li>
      <button onClick={handleClick} data-x='open-cookie-statement' aria-label={__`cookie-settings`} className={styles.componentCookieButton}>
        <span className={styles.iconContainer}>
          <Icon icon={arrowIcon} layoutClassName={styles.iconLayout} />
        </span>
        {__`cookie-settings`}
      </button>
    </li>
  )

  function handleClick() {
    // @ts-ignore
    window.BWCookieBanner?.openManually()
  }
}

export function FooterLink({ href, target, label }) {
  return (
    <li>
      <a data-x='link-in-footer' className={styles.component} {...{ href, target }}>
        <span className={styles.iconContainer}>
          <Icon icon={arrowIcon} layoutClassName={styles.arrowIconLayout} />
        </span>
        {label}
      </a>
    </li>
  )
}
